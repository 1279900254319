import { SVGProps, Ref, forwardRef } from 'react'

const SvgSoundOff = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.423 4.658a1.5 1.5 0 0 0-1.571.142L5.252 9h-3a1.5 1.5 0 0 0-1.5 1.5v3a1.5 1.5 0 0 0 1.5 1.5h3l6.6 4.2a1.5 1.5 0 0 0 2.4-1.2V6a1.501 1.501 0 0 0-.829-1.342v0ZM5.252 9v6M23.25 9.375 18 14.625M18 9.375l5.25 5.25"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgSoundOff)
export default ForwardRef
